<script setup lang="ts">

</script>

<template>
  <h1 class="font-bold text-5xl md:text-6xl">
    <slot></slot>
  </h1>
</template>

<style scoped>

</style>
